@charset "utf-8";
/*
#overview
modForm
*/

/*
#styleguide
***

```
```
*/


/* form
------------------------------------------------------------*/
textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select{
	max-width: 600px;
	width: 100%;
	border:solid 1px #aaa;
	border-radius:5px;
	padding:10px;
	&.error {
		background-color:rgba(255,242,242,1.00);
		border:solid 2px map_get($color, 'strong');
	}
}


/* radio
-------------*/
input[type="radio"] {
	@include accessibilityHidden;
	& + .label {
		cursor: pointer;
		display: inline-block;
		padding: 3px 10px 3px 24px;
		position: relative;
		width:100%;
		&::before,
		&::after {
			border-radius: 100%;
			content: '';
			position: absolute;
			top: 50%;
			transition: all .2s;
		}
		&::before {
			background: #EEE;
			border: 1px solid #ccc;
			height: 16px;
			left: 0;
			margin-top: -9px;
			width: 16px;
		}
		&::after {
			background: map_get($color, 'blue');
			height: 10px;
			left: 3px;
			margin-top: -6px;
			opacity: 0;
			transform: scale(0.5);
			width: 10px;
		}
		&:hover {
			&::before {
				background: #FEFEFE;
			}
		}
	}
	&.error {
		& + .label {
			&::before{
				background-color:rgb(255, 242, 242);
				border: 1px solid map_get($color, 'strong');
			}
		}
	}
	&:checked {
		& + .label {
			&::before {
				background: #fff;
				border: 1px solid map_get($color, 'blue');
			}
			&::after {
				opacity: 1;
				transform: scale(1);
			}
		}
	}
}

/* checkbox
-------------*/
input[type="checkbox"] {
	@include accessibilityHidden;
	& + .label {
		cursor: pointer;
		display: inline-block;
		padding: 3px 10px 3px 22px;
		position: relative;
		transition: all .2s;
		&::before,
		&::after {
			content: '';
			position: absolute;
		}
		&::before {
			background: #f4f4f4;
			border: 1px solid #ccc;
			border-radius: 3px;
			height: 14px;
			left: 0;
			margin-top: -8px;
			top: 50%;
			width: 14px;
		}
		&::after {
			border-bottom: 2px solid map_get($color, 'blue');
			border-left: 2px solid map_get($color, 'blue');
			height: 4px;
			left: 3px;
			margin-top: -4px;
			opacity: 0;
			top: 50%;
			transform: rotate(-45deg) scale(0.5);
			width: 8px;
		}
		&:hover {
			&::before {
				background: #fff;
			}
		}
	}
	&:checked {
		& + .label {
			&::before {
				background: #fff;
				border: 1px solid map_get($color, 'blue');
			}
			&::after {
				opacity: 1;
				transform: rotate(-45deg) scale(1);
			}
		}
	}
}

.wpcf7-form-control.wpcf7-radio{
	display: flex;
	flex-wrap: wrap;
}
input[type="radio"]{
	position: absolute;
	opacity: 0;
}
span.wpcf7-list-item{
	position: relative;
	margin: 0 1em 0 0!important;
}
.wpcf7-list-item-label{
	color: #333;
	cursor: pointer;
	font-size: 13px
}

#contact_type .wpcf7-list-item-label:before{
	content: '';
	background: #fff;
	border-radius: 100%;
	border: 1px solid #888888;
	display: inline-block;
	width: 1.4em;
	height: 1.4em;
	position: relative;
	top: -0.2em;
	margin-right: 14px;
	vertical-align: middle;
	cursor: pointer;
	text-align: center;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

#contact_type input[type="radio"]:checked ~ .wpcf7-list-item-label:before{
	background-color: #404040;
	box-shadow: inset 0 0 0 2px #fff;
}

#plan .wpcf7-list-item-label:before{
	content: '';
	background: #fff;
	border-radius: 100%;
	border: 1px solid #888888;
	display: inline-block;
	width: 1.4em;
	height: 1.4em;
	position: relative;
	top: -0.2em;
	margin-right: 14px;
	vertical-align: middle;
	cursor: pointer;
	text-align: center;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

#plan input[type="radio"]:checked ~ .wpcf7-list-item-label:before{
	background-color: #404040;
	box-shadow: inset 0 0 0 2px #fff;
}

#hope .wpcf7-list-item-label:before{
	content: '';
	background: #fff;
	border-radius: 100%;
	border: 1px solid #888888;
	display: inline-block;
	width: 1.4em;
	height: 1.4em;
	position: relative;
	top: -0.2em;
	margin-right: 14px;
	vertical-align: middle;
	cursor: pointer;
	text-align: center;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

#hope input[type="radio"]:checked ~ .wpcf7-list-item-label:before{
	background-color: #404040;
	box-shadow: inset 0 0 0 2px #fff;
}

#hope-facility .wpcf7-list-item-label:before {
	content: '';
	background: #fff;
	border-radius: 100%;
	border: 1px solid #888;
	display: inline-block;
	width: 1.4em;
	height: 1.4em;
	position: relative;
	top: -0.2em;
	margin-right: 14px;
	vertical-align: middle;
	cursor: pointer;
	text-align: center;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease
}
#hope-facility input[type=radio]:checked~.wpcf7-list-item-label:before {
	background-color: #404040;
	-webkit-box-shadow: inset 0 0 0 2px #fff;
	box-shadow: inset 0 0 0 2px #fff
}
.form-num {
	width: 60px
}
