@charset "utf-8";

/*
#overview
modTitle
*/

/*
#styleguide
xxx

```
```
*/

.pTtl {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	flex-wrap: wrap;
	background: url(../img/page/bg_title.png) no-repeat center center;
	background-size: cover;
	height: 350px;
	@include tabletP{
		height: 230px;
	}
	h1 {
		color: #fff;
		font-size: 38px;
		font-weight: bold;
	}
}
