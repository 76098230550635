@charset "utf-8";
/*
#overview
modBlock
*/

/*
#styleguide

```
```
*/

.modBlockMap01 {
	height: 600px;
	position: relative;
	@include tabletP {
		height: 450px;
	}
	iframe {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
