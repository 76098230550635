@charset "utf-8";
/*
#overview
modList
*/

/*
#styleguide
modList　modIndent

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
<ul class="modIndent01">
    <li>※テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>※リスト</li>
</ul>
<ul class="modIndent02">
    <li>１.　テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>２.　リスト</li>
</ul>
<ul class="modList01">
    <li>Illustrator に原寸で配置したサイズでの解像度が350dpiになるようにしてください。</li>
    <li> 拡大縮小率70%〜141％を超えると画質が劣化するのでご注意ください。</li>
</ul>
```
*/

.modListNormal { margin: 0 0 0 2em; }

ul.modListNormal {
    li{
        &:before {
            content: "\0030fb";
            display: inline-block;
            margin-left:-2em;
            text-align:center;
            width:2em;
        }
    }
}

ol.modListNormal li { list-style:decimal; }

/* indent */
.modIndent01 li {
    margin: 0 0 0 1em;
    text-indent: -1em;
}

.modIndent02 li {
    margin: 0 0 0 2.5em;
    text-indent: -2.5em;
}

.modDlForm{
    margin:0 0 20px;
    dt{
        clear:both;
        float:left;
        padding:33px 0 20px 63px;
        position:relative;
        width:16em;
        @include tabletP{
            float:none;
            padding:33px 0 0 63px;
            width:auto;
        }
        &.required:before,
        &.any:before{
            color:#FFF;
            @include fz(11);
            left:0;
            padding:2px 10px;
            position:absolute;
            top:33px;
        }
        &.required:before{
            background:map_get($color, 'strong');
            content:"必須";
        }
        &.any:before{
            background:#999999;
            content:"任意";
        }
    }
    dd{
        border-bottom:solid 1px #ccc;
        padding:10px 0;
        padding-left:17em;
        @include tabletP{
            padding:10px 0;
        }
        .parts{
            padding:10px 0;
            textarea,
            input[type="text"],
            input[type="email"] {
                width:100%;
            }
            &.radio_inline,
            &.check_inline {
                > div{
                    display:inline-block;
                }
            }
            &.name{
                display:flex;
                justify-content:space-between;
                >div{
                    width:48.5%;
                }
            }
            &.post,
            &.tel {
                display:flex;
                .hyphen{
                    padding:10px 10px 0 10px;
                    @include sphoneP{
                        padding:10px 5px 0 5px;
                    }
                }
            }
            &.password{
                input{
                    max-width:300px;
                    width:100%;
                    @include sphoneP{
                        max-width:none;
                    }
                }
                .text{
                    padding:10px 0;
                }
            }
        }
    }
    .validationError{
        color:map_get($color, 'strong');
    }
}

.modListBreadcrumb {
    &__item {
        display: inline;
        @include tabletP{
        }
        &:not(:last-child):after {
            content:'\3E';
            margin: 0 8px;
        }
        &.-home {
            &:before{
                display: none;
            }
        }
    }
    &__link {
        &:hover{
        }
    }
}//.modListBreadcrumb


.modListNews {
    &__item {
        border-bottom: 1px solid #C8C5C5;
    }
    &__link {
        color: #404040;
        display: flex;
        @include fz(15);
        flex-wrap: wrap;
        line-height: 1.2;
        padding: 15px 0;
        text-decoration: none;
        &:hover {
            color: #404040;
            .modListNews__txt {
                text-decoration: underline;
            }
        }
    }
    &__date {
        padding: 0 10px;
        text-align: center;
        width: 110px;
        @include tabletP {
            letter-spacing: .05em;
            margin-bottom: 5px;
            padding: 0;
            text-align: left;
            width: 100%;
        }
    }
    &__txt {
        padding: 0 10px;
        width: calc(100% - 110px);
        @include tabletP {
            padding: 0;
            width: 100%;
        }
    }
}

ul.modListJump {
    display: table;
    table-layout: fixed;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: #3C379E;
    padding: 10px 0;
    width: 100%;
    margin: 0 auto 100px;
    li{
        display: table-cell;
        padding: 0 15px;
        border-right: 1px #7EA1BE solid;
        margin-bottom: 0;
        text-align: center;
        @include sphoneP {
            padding: 0 5px;
        }

        &:last-child{
            border-right: none;
        }
        &:before {
            display: none;
        }
        a{
            color: #fff;
            position: relative;
            padding: 0 0 0 30px;

            @include sphoneP {
                padding: 0 0 0 15px;
            }
            
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 5px;
                height: 5px;
                border-top: 1px solid #fff;
                border-right: 1px solid #fff;
                transform: translate(5px,-50%) rotate(135deg);
            }
        }
    }
}

ul.modListSns {
    .snsicon1,
    .snsicon2,
    .snsicon3{
        position: fixed;
        right: 5px;
        img{
            width: 35px;
        }
    }
    .snsicon1{
        bottom: 210px;
    }
    .snsicon2{
        bottom: 170px;
    }
    .snsicon3{
        bottom: 130px;
    }
}
