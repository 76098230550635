@charset "utf-8";

/*
#overview
pankuzu
wp-pagenavi
*/

/*
#styleguide
xx

```
```
*/
#pankuzu {
	padding-bottom: 50px;
	ul {
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		li {
			color: #707070;
			a{
				color: #707070;
				position: relative;
				margin-right: 45px;
				&:after {
					content: "";
					position: absolute;
					top: 7px;
					right: -25px;
					width: 7px;
					height: 7px;
					border-top: 2px solid #626262;
					border-right: 2px solid #626262;
					transform: rotate(45deg);
				}
			}
		}
	}
}
.wp-pagenavi {
	display: table;
	margin: 0 auto 20px;
	border-collapse: separate;
	border-spacing: 15px 0;
	a,
	span {
		font-weight: bold;
		height: 40px;
		width: 40px;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
		padding: 0 !important;
	}
	a {
		border: 2px solid #DFDFDB !important;
		&:hover {
			border: 2px solid #0060AE !important;
			color: #fff;
			background: #0060AE;
			text-decoration: none;
		}
		&.previouspostslink,
		&.nextpostslink{
			color: #fff;
			border: 2px solid #9B9EA4 !important;
			background: #9B9EA4;
		}
	}
	span {
		border: 2px solid #0060AE !important;
		color: #fff;
		background: #0060AE;
	}
}




