@charset "utf-8";

.drawer-navbar {
	.drawer-menu li .contact {
	  background-color: #3C379E;
	  border: 1px #3C379E solid;
	  color: #fff;
	  padding: 0 10px;
  
	  &:hover {
		background-color: #fff;
		color: #3C379E;
		opacity: 1;
  
		&::after {
		  display: none;
		}
	  }
	}
  
	.drawer-menu-item {
	  position: relative;
	  display: inline-block;
	  text-decoration: none;

	  &:hover, &:visited {
		  color: inherit;
	  }
	  &::after {
		position: absolute;
		bottom: 15px;
		left: 21%;
		content: "";
		width: 0;
		margin: 0 auto;
		height: 2px;
		background: #3C379E;
		transform: scale(0, 1);
		transform-origin: left top;
		transition: transform .3s;
		
		@include tabletP {
			left: 0;
		}
	  }
  
	  &:hover::after {
		transform: scale(1, 1);
		width: 60%;

		@include tabletP {
			width: 100%;
		}
	  }
	}
  }
  
  .nav-footer-manu {
	background-color: #3C379E;
	margin-top: auto;
	padding: 30px 20px;
	text-align: center;
	max-width: 100%;
	margin-top: auto;
	left: 0;
	bottom: 0;
  
	.title {
	  background-color: #fff;
	  color: #3C379E;
	  font-weight: 600;
	  border-radius: 25px;
	  margin: 0 auto 10px;
	  padding: 5px;
	  text-align: center;
	  width: 100%;
	}
  
	.context {
	  color: #fff;
	  margin-bottom: 10px;
	}
  }
  
  /* ~1024px */
  @media screen and (max-width: 64em) {
	.drawer-navbar {
	  .drawer-menu li {
		text-align: center;
  
		.contact {
		  background-color: transparent;
		  color: inherit;
		  border: none;
		  padding: .75rem 0;
  
		  &:hover {
			color: #343434;
			opacity: .8;
  
			&::after {
			  display: block;
			}
		  }
		}
	  }
  
	  .drawer-menu-item {
		padding: .75rem 0;
  
		&::after {
		  bottom: 8px;
		}
	  }
  
	  .drawer-menu li .contact:hover::after {
		display: block;
	  }
	}
  }