@charset "UTF-8";

/* link
------------------------------------------------------------*/
a {
    color: map_get($base, 'color:link');
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}
a,button{
    &:hover {
        opacity:0.8;
        @include tabletL {
            opacity:1;
        }
    }
}

/* Fonts
------------------------------------------------------------*/
html {
    transition: font-size .3s;
    &[data-font='small'] {
        font-size: 12px;
        @include tabletL {
            font-size: 16px;
        }
    }
    &[data-font='large'] {
        font-size: 18px;
        @include tabletL {
            font-size: 16px;
        }
    }
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font: 100%/1.6;
    font-size: 62.5%;/* root size: 10px */
    -ms-text-size-adjust: 100%;/* IE on Windows Phone用 */
    -webkit-text-size-adjust: 100%;/* iOS用 */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

body{
    color: map_get($base, 'color');
    @include fz;
    font-family: $fontBase;
    font-weight: 400;
    line-height: map_get($base, 'line-height');
    overflow-wrap : break-word;
    -webkit-text-size-adjust: 100%;
    word-wrap : break-word;
    @include tabletP {
        @include fz(13);
    }
    //IE11以下は別のフォントを指定する場合
    // &.IE {
    // 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
    // }

    background: #fdfbfa;
    padding: 0;
    margin: 0 auto;
    text-rendering: optimizeLegibility;
    height: 100vh;
    -moz-font-feature-settings: "palt";
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    word-break: break-all;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input,button,textarea,select{
    color: map_get($base, 'color');
    font-family:$fontBase;
    line-height: map_get($base, 'line-height');
    //IE11以下は別のフォントを指定する場合
    // .IE &{
    // 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
    // }
}

.modContainer {
    margin-left: auto;
    margin-right: auto;
    max-width: map_get($container, 'lg');
    padding-left:  map_get($padding, 'container')+px;
    padding-right: map_get($padding, 'container')+px;
}
.modPcOnly {
    @include tabletP {
        display:none;
    }
}
.modSpOnly {
    display:none;
    @include tabletP {
        display: block;
    }
}
br.modSpOnly {
    @include tabletP {
        display: inline;
    }
}

/* Base
------------------------------------------------------------*/
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 0;
    min-width: 0;
    zoom: 1;/* IE対策 */

    &:before, &:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
        min-height: 0;
        min-width: 0;
        zoom: 1;/* IE対策 */
    }

    &:focus {
        outline: none !important;
    }
}

::selection,
::-moz-selection {
    background: #3C379E!important;
    color: #fff;
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100vw;
    min-height: 100%;
    position: relative;
    transition: 0.3s;
}

main {
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 100%;
    height: auto !important;/*IE6対策*/
    height: 100%;/*IE6対策*/
    min-height: 100%;
    overflow: hidden;
}

section {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0;

    &::before, &::after {
        position: absolute;
        content: "";
        pointer-events: none;
    }
}

.sec-inner {
    padding: 80px 0;
    margin: 0;
}

footer {
    margin-top: auto;
    width: 100%;
}

div, span, object, iframe, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 16px;
    vertical-align: middle;
    background: transparent;

    @include tabletP {
        font-size: 1.4rem;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    line-height: 150%;
    @include tabletP {
        margin-bottom: 1rem;
    }
}

h1 {
    font-size: 4rem;

    @include tabletP {
        font-size: 3rem;
    }
}

h2 {
    font-size: 3.2rem;

    @include tabletP {
        font-size: 2.6rem;
    }
}

h3 {
    font-size: 2.8rem;

    @include tabletP {
        font-size: 2.2rem;
    }
}

h4 {
    font-size: 2.4rem;

    @include tabletP {
        font-size: 2rem;
    }
}

h5 {
    font-size: 2rem;

    @include tabletP {
        font-size: 1.8rem;
    }
}

h6 {
    font-size: 1.6rem;

    @include tabletP {
        font-size: 1.4rem;
    }
}

ol, ul {
    list-style: none;
    margin: 0;
    outline: none !important;
}

a {
    color: #3C379E;
    text-decoration: none;
    vertical-align: middle;
    display: inline-block;

    /* ▼下線跡切れ対策 */
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;

    &:hover {
        color: #3C379E;
        text-decoration: underline;
        opacity: 0.8;
    }

    &:visited {
        color: #3C379E;
    }

    &:focus, &:active {
        color: #3C379E;
        text-decoration: none;
        outline: none !important;
    }

    [href$=".png"],
    [href$=".jpg"],
    [href$=".jpeg"],
    [href$=".jpe"],
    [href$=".gif"],
    [href$=".bmp"],
    [href$=".ico"] {
        border: none;
    }
}

p {
    margin-bottom: 0;
    font-size: inherit;
    text-rendering: optimizeLegibility;
}

img {
    width: 100%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;

    /* vertical-align: bottom; IEのimgタグ用 */
    flex-shrink: 0;/* IE対策 */
    -ms-interpolation-mode: bicubic;/* IE対策 */
}

button {
    margin: 0;
    padding: 0;
}

/* IE11用ハック svg崩れ対策 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img[src$=".svg"] {
        width: 100%;
    }
}

/*====================================

Bootstrap CSS

====================================*/
.container-fluid {
    max-width: 1200px;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
}

.container-full {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
}

.row {
    margin-left: 0;
	margin-right: 0;

	@include sphoneP {
		margin-left: 15px;
		margin-right: 15px;
	}
}


