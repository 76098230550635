@charset "utf-8";

  /*---------- Mediaquery ----------*/
  /* ~576px */
  @media screen and (max-width: 36em) {
	footer {
	  text-align: center;
	}
  }
  
  /* ::: コピーライト ::: */
  .copyright {
	color: rgba(54, 54, 54, 0.3);
	font-size: 14px;
  }
  
  /*---------- Mediaquery ----------*/
  /* ~576px */
  @media screen and (max-width: 36em) {
	.copyright {
	  font-size: 12px;
	}
  }
  
  /* トップへ戻る
  ====================================*/
  #page-top {
	background-color: #5DBAEB;
	position: fixed;
	right: 0;
	width: 50px;
	height: 50px;
	z-index: 9000;
  
	a {
	  display: block;
	  width: 50px;
	  height: 50px;
	  text-decoration: none;
	  text-align: center;
	  position: relative;
  
	  &::before {
		content: "";
		height: 15px;
		width: 15px;
		display: block;
		border: 2px solid #fff;
		border-left-width: 0;
		border-bottom-width: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -30%) rotate(-45deg);
	  }
	}
  }
