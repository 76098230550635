
@charset "utf-8";
/*
#overview
base
*/

body {
	&.-noscroll {
		overflow: hidden;
		@include tabletP {
			height: 100vh;
			left: 0;
			position: fixed;
			width: 100%;
		}
	}
}

#wrapper {
	overflow: hidden;
}

@import './layout/_header';
@import './layout/_contents';
@import './layout/_footer';
